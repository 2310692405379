import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadActivityUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { ActivityMap } from '../types';

export const loadAccountActivity = (activityId: string): Observable<{ result: ActivityMap }> => {
    const url = buildLoadActivityUrl(activityId);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<ActivityMap>) => {
            const result = convertToCamel<ActivityMap>(response);
            return observableOf({ result });
        }),
    );
};
